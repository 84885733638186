/**
* ideas2pixels
* Base
*/

* {
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100%;
}

html {
  font-family: $gt-walsheim;
  font-size: $font-size-sm;
  line-height: $line-height;
  font-weight: 400;
  letter-spacing: 0.62px;
  color: $grey;
  background: $white;
  -webkit-font-smoothing: antialiased;

  @media #{$breakpoint-lg} {
    font-size: $font-size;
  }
}


::selection {
  background: $blue;
  color: $white;
}

a, &:visited {
  text-decoration: none;
  transition-property: color, background;
  transition-duration: 0.2s;
  transition-timing-function: linear;
}


//
// Helper
//

.fit {
  width: 100%;
}


//
// Panel + Menu
//

.mobile-menu {
  background: $medium-blue;
}

.panel {
  box-shadow: 1px 0 5px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}
