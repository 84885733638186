/**
* ideas2pixels
* Inner
*/

.page--inner {

  .section--top {
    padding-top: 120px;

    @media #{$breakpoint-md} {
      padding-top: 140px;
    }
  }

}
