@mixin brandGradient() {
  background-image: linear-gradient(125deg, $dark-blue 0%, $medium-blue 40%, $light-blue 86%);
}

@mixin absoluteCover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@mixin serviceGridCircleAfter {
  content: "";
  width: 50px;
  height: 50px;
  border-radius: 50%;
  top: 100%;
  left: 100%;
  position: absolute;
  transform: translate(-50%, -50%);
  background: $white;
  z-index: 10;
}
