.site-footer {
  background: $white;
  padding-top: 40px;
  padding-bottom: 30px;

  @media #{$breakpoint-md} {
    padding-top: 78px;
    padding-bottom: 73px;
  }

  a[href*=top] {
    display: block;
  }

  &__row {
    @media #{$breakpoint-md} {
      padding-bottom: 68px;
    }
    &:last-of-type {
      padding-bottom: 0;
    }

    .col,
    .col-right {
      @media #{$breakpoint-mobile} {
        width: 100%;
        margin-bottom: 30px;
      }
    }

    .social-nav {
      @media #{$breakpoint-mobile} {
        margin-bottom: 0;
      }
    }

  }

}
