.section {
  position: relative;
  padding-top: $section-padding-top / 2;
  padding-bottom: $section-padding-bottom / 2;

  &#quote {
    padding-top: $section-padding-top;
  }

  @media #{$breakpoint-md} {
    padding-top: $section-padding-top;
    padding-bottom: $section-padding-bottom;
  }

  &__content {
    position: relative;
    @extend .clearfix;
  }

  &--angle {
    &:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 100%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 30px 100vw 0 0;
      border-color: transparent #fff transparent transparent;
    }

    &.bg-grey {
      padding-bottom: 20px;
      z-index: 2;
      &:before {
        border-width: 0 100vw 30px 0;
        border-color: transparent transparent $light-grey transparent;
      }
      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 100%;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 30px 100vw 30px 0;
        border-color: $light-grey transparent transparent transparent;
      }
    }
  }
}
