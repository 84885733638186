h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-family: $gt-walsheim;
}

.header-group {
  padding-bottom: 20px;
}

.h1 {
  letter-spacing: 1.5px;
  font-weight: 600;
  font-family: $gt-walsheim;
  margin-bottom: 30px;
  font-size: 30px;
  line-height: 1.2;
  @media #{$breakpoint-md} {
    font-size: 38px;
  }
  @media #{$breakpoint-lg} {
    font-size: 48px;
  }
}

.h2 {
  margin-bottom: 20px;
  font-size: 16px;
  @media #{$breakpoint-md} {
    font-size: 18px;
  }
  @media #{$breakpoint-lg} {
    font-size: 20px;
  }
}
.h3 {
  margin-bottom: 20px;
  font-size: 16px;
  @media #{$breakpoint-md} {
    font-size: 18px;
  }
  @media #{$breakpoint-lg} {
    font-size: 27px;
  }
}
.h4 {
  margin-bottom: 20px;
  font-size: 14px;
  @media #{$breakpoint-md} {
    font-size: 16px;
  }
  @media #{$breakpoint-lg} {
    font-size: 18px;
  }
}

p {
  margin: 0 0 20px;
}




.text-white {
  color: $white;
}

.text-dark-blue {
  color: $dark-blue;
}

.text-light-blue {
  color: $light-blue;
}

.text-orange {
  color: $orange;
}

.text-red {
  color: $red;
}
