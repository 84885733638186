.site-nav {

  a {
    font-family: $gt-walsheim;
    font-weight: 100;
    margin-left: 55px;

    &:not(.btn) {
      color: $light-blue;
      &:hover {
        color: $dark-blue;
      }
    }
  }
}


.mobile-nav {
  list-style: none;
  padding: 20px 20px 0;


  li {
    margin-bottom: 40px;
    text-align: center;
  }

  a {
    display: block;
    font-weight: 400;

    &:not(.text-orange){
      color: $white;
    }

  }
}


.footer-nav {
  a {
    color: $dark-blue;
    margin-right: 40px;
    letter-spacing: 0;
    font-family: $gt-walsheim;
    &:hover {
      color: $orange;
    }

    &:last-of-type {
      @media #{$breakpoint-mobile} {
        margin-right: 0;
      }
    }
  }
}


.social-nav {

  a {
    margin-right: 50px;

    @media #{$breakpoint-md} {
      margin-right: 20px;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &[href*=dribbble] {
      &:hover {
        svg {
          path {
            fill: #ea4c89;
          }
        }
      }
    }

    &[href*=twitter] {
      &:hover {
        svg {
          path {
            fill: #55acee;
          }
        }
      }
    }

    &[href*=facebook] {
      &:hover {
        svg {
          path {
            fill: #3b5998;
          }
        }
      }
    }

  }
}
