
// Converted Variables


// Custom Media Query Variables

$breakpoint-sm: '(min-width: 40em)' !default;
$breakpoint-md: '(min-width: 52em)' !default;
$breakpoint-lg: '(min-width: 64em)' !default;

/* Basscss Grid */

.col {
  float: left;
  box-sizing: border-box;
}

.col-right {
  float: right;
  box-sizing: border-box;
}

.col-1 {
  width: (1/12 * 100%);
}

.col-2 {
  width: (2/12 * 100%);
}

.col-3 {
  width: (3/12 * 100%);
}

.col-4 {
  width: (4/12 * 100%);
}

.col-5 {
  width: (5/12 * 100%);
}

.col-6 {
  width: (6/12 * 100%);
}

.col-7 {
  width: (7/12 * 100%);
}

.col-8 {
  width: (8/12 * 100%);
}

.col-9 {
  width: (9/12 * 100%);
}

.col-10 {
  width: (10/12 * 100%);
}

.col-11 {
  width: (11/12 * 100%);
}

.col-12 {
  width: 100%;
}

@media #{$breakpoint-sm} {

  .sm-col {
    float: left;
    box-sizing: border-box;
  }

  .sm-col-right {
    float: right;
    box-sizing: border-box;
  }

  .sm-col-1 {
    width: (1/12 * 100%);
  }

  .sm-col-2 {
    width: (2/12 * 100%);
  }

  .sm-col-3 {
    width: (3/12 * 100%);
  }

  .sm-col-4 {
    width: (4/12 * 100%);
  }

  .sm-col-5 {
    width: (5/12 * 100%);
  }

  .sm-col-6 {
    width: (6/12 * 100%);
  }

  .sm-col-7 {
    width: (7/12 * 100%);
  }

  .sm-col-8 {
    width: (8/12 * 100%);
  }

  .sm-col-9 {
    width: (9/12 * 100%);
  }

  .sm-col-10 {
    width: (10/12 * 100%);
  }

  .sm-col-11 {
    width: (11/12 * 100%);
  }

  .sm-col-12 {
    width: 100%;
  }

}

@media #{$breakpoint-md} {

  .md-col {
    float: left;
    box-sizing: border-box;
  }

  .md-col-right {
    float: right;
    box-sizing: border-box;
  }

  .md-col-1 {
    width: (1/12 * 100%);
  }

  .md-col-2 {
    width: (2/12 * 100%);
  }

  .md-col-3 {
    width: (3/12 * 100%);
  }

  .md-col-4 {
    width: (4/12 * 100%);
  }

  .md-col-5 {
    width: (5/12 * 100%);
  }

  .md-col-6 {
    width: (6/12 * 100%);
  }

  .md-col-7 {
    width: (7/12 * 100%);
  }

  .md-col-8 {
    width: (8/12 * 100%);
  }

  .md-col-9 {
    width: (9/12 * 100%);
  }

  .md-col-10 {
    width: (10/12 * 100%);
  }

  .md-col-11 {
    width: (11/12 * 100%);
  }

  .md-col-12 {
    width: 100%;
  }

}

@media #{$breakpoint-lg} {

  .lg-col {
    float: left;
    box-sizing: border-box;
  }

  .lg-col-right {
    float: right;
    box-sizing: border-box;
  }

  .lg-col-1 {
    width: (1/12 * 100%);
  }

  .lg-col-2 {
    width: (2/12 * 100%);
  }

  .lg-col-3 {
    width: (3/12 * 100%);
  }

  .lg-col-4 {
    width: (4/12 * 100%);
  }

  .lg-col-5 {
    width: (5/12 * 100%);
  }

  .lg-col-6 {
    width: (6/12 * 100%);
  }

  .lg-col-7 {
    width: (7/12 * 100%);
  }

  .lg-col-8 {
    width: (8/12 * 100%);
  }

  .lg-col-9 {
    width: (9/12 * 100%);
  }

  .lg-col-10 {
    width: (10/12 * 100%);
  }

  .lg-col-11 {
    width: (11/12 * 100%);
  }

  .lg-col-12 {
    width: 100%;
  }

}