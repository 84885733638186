.site-header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
  height: $header-height-sm;
  background: $white;
  backface-visibility: hidden;
  will-change: background;

  @media #{$breakpoint-md} {
    height: $header-height;
    background: transparent;
    transition: background 0.2s linear;
  }

  .container {
    height: 100%;
    padding-top: 18px;
    padding-bottom: 18px;

    @media #{$breakpoint-md} {
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }

  .col-right {
    text-align: right;
  }

  .logo {
    display: block;
    svg {
      width: 150px;
      height: auto;
      @media #{$breakpoint-md} {
        width: 220px;
      }
    }
  }

  .menu-button {
    float: right;
    margin-top: 8px;
    @media #{$breakpoint-md} {
      display: none;
    }
  }

  &:not(.site-header--fixed) {
    @media #{$breakpoint-md} {
      .logo {
        svg {
          path {
            fill: $white;
          }
        }
      }
      .site-nav {
        a {
          color: $white;
        }
      }
    }
  }

  &--fixed {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background: $white;
    opacity: 1;
    border-bottom: 1px solid $light-grey;
  }

  .site-nav {
    display: none;
    @media #{$breakpoint-md} {
      display: block;
    }
  }

}
