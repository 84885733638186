//
// Remove Outline on .btn class
//

.btn {
  padding: 0.9em 1.5em;
  &:focus {
    outline: 0;
  }
  &.btn--rounded {
  }
}


//
// Remove basic button styling
//

.btn--plain {
  background: none;
  border: 0;
  outline: 0;
}


//
// Hero down button
//


.btn--more-arrow {
  display: block;
  margin-top: 30px;

  @media #{$breakpoint-md} {
    margin-top: 60px;
  }
}


//
// Button Primary (from basscss)
//


.btn-primary {
  &:focus {
    outline: 0;
  }
  
  &.btn--orange {
    background: $orange;
    color: $white;
  }

  &.btn--tea {
    background: $tea;
    color: $white;
    text-shadow: 1px 1px rgba(0,0,0,0.2);
  }
  &.btn--lg {
    font-size: 24px;
    padding: 1em 2em;
    letter-spacing: 0.15em;
  }
}

//
// Button Outline (from basscss)
//


.btn-outline {
  &:focus {
    border-color: currentColor;
    box-shadow: none;
  }
  &.btn--orange {
    color: $orange;

    &:hover, &:active {
      background: $orange;
      border-color: $orange;
      color: $white;
    }
  }
}


//
// Menu Button
//


.menu-button {
  position: relative;
  display: block;
  width: 30px;
  height: 24px;
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  border: 0;
  transition: all 0.3s;
  outline: 0;
  -webkit-tap-highlight-color: rgba(0,0,0,0);

  > span {
    position: absolute;
    top: 50%;
    display: block;
    width: 100%;
    height: 3px;
    margin-top: -3px;
    background-color: $dark-blue;
    border-radius: 3px;

    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      background-color: $dark-blue;
      border-radius: 3px;
      transition: all 0.3s;
    }

    &:before {
      transform: translateY(-8px);
    }

    &:after {
      transform: translateY(8px);
    }

  }
}


//
// Menu Opened Button
//


.slideout-open {
  .menu-button {
    transform: rotate(45deg);

    > span {
      &:before {
        transform: rotate(90deg);
      }
      &:after {
        transform: rotate(90deg);
      }
    }
  }
}


//
// Attachment Button
//

.attach-btn {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 0;
  outline: 0;
  background-color: transparent;

  span {
    width: 18px;
    height: 20px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url('../icons/paperclip.svg');
    background-size: 18px 20px;

    @media #{$breakpoint-md} {
      width: 37px;
      height: 41px;
      background-size: 37px 41px;
    }

  }


  &__wrap {
    position: absolute;
    width: 44px;
    height: 44px;
    right: 20px;
    bottom: 26px;
    cursor: pointer;

    input[type=file] {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 5;
    }

    @media #{$breakpoint-md} {
      width: 90px;
      right: 26px;
      height: 90px;
    }

    &.loading {
      .attach-btn__spinner {
        border-color: $white $white $orange $white;
        animation: spin .5s infinite linear;
      }
    }
  }

  &__spinner {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid $white;
    position: absolute;
    top: 0;
    left: 0;
  }

}
