.timeline {

  &__list {
    margin: 80px 0 0;
    padding: 0;
    list-style: none;
    position: relative;

    .line {
      width: $timeline-list-line-width;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -($timeline-list-line-width / 2);
      background-image: linear-gradient($light-blue 0%, $light-blue 86%, rgba($light-blue, 0.05));
    }
  }

}


//
// Timeline item
//

.timeline-item {
  width: 100%;
  background: $white;
  padding: $timeline-item-padding-sm;
  border: $timeline-item-border;
  box-shadow: $timeline-item-shadow;
  border-radius: $radius;
  margin-bottom: 50px;
  float: right;
  clear: both;
  position: relative;
  z-index: 2;

  @media #{$breakpoint-md} {
    width: $timeline-item-width-desktop;
    margin-bottom: 100px;
    padding: $timeline-item-padding;
  }

  &:nth-of-type(even) {
    float: left;
  }

  &__content {
    padding-left: 0;
    padding-top: 50px;
    text-align: center;

    @media #{$breakpoint-md} {
      padding-top: 0;
      padding-left: 90px;
      text-align: left;
    }
  }

  &__icon {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);

    @media #{$breakpoint-md} {
      top: 50%;
      left: 40px;
      transform: translateY(-50%);
    }
  }

  &__title {
    font-size: 20px;
    color: $dark-blue;
    font-weight: 600;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    color: $grey;
    margin-bottom: 0;

    @media #{$breakpoint-md} {
      font-size: 16px;
    }
  }

}
