.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  max-width: $container-width + 20;
  @extend .clearfix;

  &:not(.container--wide) {
    text-align: center;
  }

  @media #{$breakpoint-md} {
    padding-left: 20px;
    padding-right: 20px;
    max-width: $container-width + 40;
  }

  &__inner {
    margin-left: auto;
    margin-right: auto;

    @media #{$breakpoint-md} {
      width: calc(100% - 140px);

      &--large {
        width: calc(100% - 220px);
      }
    }

    &--text {
      h1, h2, h3, h4, h5, h6 {
        text-align: left;
      }
      h1 {
        @extend .h1;
        @extend .text-dark-blue;
      }
      h2 {
        @extend .h2;
        @media #{$breakpoint-lg} {
          font-size: 35px;
        }
        @extend .text-light-blue;
      }
      h3 {
        @extend .h3;
        @media #{$breakpoint-lg} {
          font-size: 27px;
        }
        @extend .text-light-blue;
      }
      h4 {
        @extend .h4;
        @extend .text-light-blue;
      }
      ul, ol {
        li {
          text-align: left;
        }
      }
      p {
        text-align: left;
      }
      a {
        @extend .text-light-blue;
        &:hover {
          @extend .text-dark-blue;
        }
      }
    }
  }

  &--wide {
    max-width: $container-width-wide + 20;
    @media #{$breakpoint-md} {
      max-width: $container-width-wide + 40;
    }
  }


}
