.bg-grey {
  background: $light-grey;
}

.bg-white {
  background: $white;
}

.bg-gradient {
  @include brandGradient;
  color: $white;
}
