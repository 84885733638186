.hr-block {
  @extend .clearfix;
  position: relative;

  &:before {
    @media #{$breakpoint-md} {
      content: "";
      width: 100%;
      height: 1px;
      background: $light-blue;
      position: absolute;
      top: 50%;
      left: 0;
    }
  }

  &__content {
    background: $white;
    position: relative;
    z-index: 2;
    font-size: 14px;
    color: $muted-blue;

    @media #{$breakpoint-md} {
      display: flex;
      align-items: center;
    }

    span {
      @media #{$breakpoint-mobile} {
        display: block;
        text-align: center;
        margin-bottom: 30px;
      }
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &--right {
    .hr-block__content {
      @media #{$breakpoint-md} {
        float: right;
      }
    }
  }

}
