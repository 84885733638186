
// Converted Variables

$h1: 2rem !default;
$h2: 1.5rem !default;
$h3: 1.25rem !default;
$h4: 1rem !default;
$h5: .875rem !default;
$h6: .75rem !default;

// Custom Media Query Variables


/* Basscss Type Scale */

.h1 { font-size: $h1 }

.h2 { font-size: $h2 }

.h3 { font-size: $h3 }

.h4 { font-size: $h4 }

.h5 { font-size: $h5 }

.h6 { font-size: $h6 }