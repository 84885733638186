/**
* ideas2pixels
* HomePage
*/

.page--home {

  .section--top {
    padding-top: 120px;

    @media #{$breakpoint-md} {
      padding-top: 140px;
    }
  }

}
