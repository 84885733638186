//
// Mobile Menu
//

.mobile-menu {
  display: none;
}

.slideout-menu {
  position: fixed;
  left: auto;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  width: 256px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: none;
}

.slideout-panel {
  position:relative;
  z-index: 1;
  will-change: transform;
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden;
}

.slideout-open .slideout-menu {
  display: block;
}

.js-fixed {
  backface-visibility: hidden;
  position: fixed;
  transition: transform 300ms ease;
}

.fixed-open {
  transform: translate3d(-256px, 0px, 0px);
}
