.form {
  padding: 45px 0;

  &__message {
    display: flex;
    align-items: center;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 0;

    @media #{$breakpoint-md} {
      width: 70%;
    }

    &:last-of-type {
      padding-top: 30px;
    }

    > i {
      width: 80px;
      display: block;
      flex-shrink: 0;
      flex-grow: 0;

      @media #{$breakpoint-md} {
        width: 90px;
      }
    }

    p {
      margin-bottom: 0;
    }

    &--success {
      > i {
        padding-left: 10px;
      }
    }

  }


}

.input-outline {
  background: none;
  outline: 0;
  border: 2px solid $white;
  border-radius: $input-radius;
  font-weight: 400;
  font-size: 20px;
  font-family: inherit;
  margin-bottom: 20px;
  padding: 21px;
  transition: 0.2s ease;

  &::placeholder {
    color: $white;
    font-weight: 300;
  }

  &.invalid {
    border-color: tomato;
  }

}

label {
  text-align: left;
  display: block;
  margin: 0 0 10px 5px;
  font-size: 16px;
  font-weight: bold;
  text-shadow: 1px 1px rgba(0,0,0,0.2);
}

textarea {
  width: 100%;
  min-height: 250px;
  resize: none;

  &.input-outline {
    padding-right: 150px;
    margin-bottom: 0;
  }
}

.attach-btn {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.form__footer {
  padding: 20px 0;

  @media #{$breakpoint-md} {
    padding: 40px 0;
  }
}


button[type=submit] {
  padding-top: 1rem;
  padding-bottom: 1rem;
  min-width: 250px;
  font-size: 24px;
  line-height: 1.25;
  border-radius: 5px;

  @media #{$breakpoint-mobile} {
    width: 100%;
    font-size: 18px;
  }
}


.select {
  margin-bottom: 20px;
}

.loader {
  margin: 60px auto;
  font-size: 10px;
  top: 0;
  right: 25px;
  text-indent: -9999em;
  border-top: 0.35em solid rgba(255, 255, 255, 0.2);
  border-right: 0.35em solid rgba(255, 255, 255, 0.2);
  border-bottom: 0.35em solid rgba(255, 255, 255, 0.2);
  border-left: 0.35em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load 1.1s infinite linear;
  animation: load 1.1s infinite linear;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 3em;
  height: 3em;
}
@-webkit-keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
