//
// Fancy-select (plugin) custom styles.
//


div.fancy-select {
  position: relative;
  font-weight: bold;
  font-size: 16px;
  color: #46565D;
  user-select: none;

  &.disabled {
    opacity: 0.5;
  }
}

div.fancy-select.disabled {
  opacity: 0.5;
}

div.fancy-select select:focus + div.trigger.open {
  box-shadow: none;
}

div.fancy-select div.trigger {
  background: none;
  outline: 0;
  border: 2px solid $white;
  border-radius: $input-radius;
  font-weight: 600;
  font-size: 20px;
  font-family: inherit;
  color: $white;
  font-weight: 300;
  margin-bottom: 20px;
  padding: 16px 20px;
  transition: 0.2s ease;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  position: relative;
  width: 100%;
  transition: all 240ms ease-out;
}

div.fancy-select div.trigger:after {
  content: "";
  display: block;
  position: absolute;
  width: 20px;
  height: 11px;
  top: 50%;
  right: 25px;
  background-image: url('../icons/chevron-down.svg');
  transform: translateY(-50%);
}

div.fancy-select div.trigger.open {
  background: $white;
  color: $dark-blue;
  border-radius: $input-radius $input-radius 0 0;
}

div.fancy-select div.trigger.selected {
  font-weight: 400;
}

div.fancy-select div.trigger.open:after {
  background-image: url('../icons/chevron-up-blue.svg');
}

div.fancy-select ul.options {
  padding: 0 0 15px;
  text-align: left;
  list-style: none;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
  visibility: hidden;
  opacity: 0;
  z-index: 50;
  max-height: 250px;
  overflow: auto;
  background: $white;
  border-radius: 0 0 $input-radius $input-radius;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  width: 100%;
  transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
}

div.fancy-select ul.options.open {
  visibility: visible;
  opacity: 1;

  /* have to use a non-visibility transition to prevent this iOS issue (bug?): */
  /*http://stackoverflow.com/questions/10736478/css-animation-visibility-visible-works-on-chrome-and-safari-but-not-on-ios*/
  transition: opacity 300ms ease-out, top 300ms ease-out;
}

div.fancy-select ul.options.overflowing {
  top: auto;
  bottom: 100%;
  padding: 15px 0 0;
  border-radius: $input-radius $input-radius 0 0;
  transition: opacity 300ms ease-out, bottom 300ms ease-out, visibility 300ms ease-out;
}

div.fancy-select ul.options.overflowing.open {
  top: auto;
  bottom: 100%;
  margin-bottom: -3px;
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.2);
  transition: opacity 300ms ease-out, bottom 300ms ease-out;
}

div.fancy-select ul.options li {
  padding: 8px 20px;
  color: $dark-blue;
  font-weight: 300;
  cursor: pointer;
  white-space: nowrap;
  transition: all 150ms ease-out;
}

div.fancy-select ul.options li.selected {
  background: $dark-blue;
  color: $white;
}

div.fancy-select ul.options li.hover:not(.selected) {
  background: $light-grey;
  color: $dark-blue;
}
