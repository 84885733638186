.service {

  &__grid {
    display: flex;
    flex-wrap: wrap;
    padding-top: 35px;
    margin-bottom: 30px;

    @media #{$breakpoint-md} {
      padding-top: 50px;
    }
  }

  &__overlay {
    padding: 30px 20px 0;

    @media #{$breakpoint-md} {
      @include absoluteCover;
      background: $service-overlay-bg;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      padding: 40px;
      will-change: opacity;
    }

    p {
      font-size: 16px;
      color: $dark-blue;
      margin-bottom: 0;
      letter-spacing: 0.5px;
      line-height: 28px;
      @media #{$breakpoint-md} {
        position: absolute;
        left: 40px;
        right: 40px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 600;
    color: $black;
  }

  &__icon {
    svg {
      max-width: 100%;
      height: auto;
    }
    margin-bottom: 15px;
  }

  &__border {
    top: 0;
    left: 0;
    position: absolute;
    z-index: 11;
    opacity: 0;
    transition: 0.3s ease-in-out;
    will-change: opacity, transform;

    &--x {
      transform: scale(0, 1);
    }

    &--y {
      height: calc(100% + 15px);
      right: -1px;
      left: -1px;
      transform: scale(1, 0);
    }
  }

  &__item {
    position: relative;
    padding-top: 30px;
    padding-bottom: 50px;
    box-sizing: border-box;
    width: 100%;
    border-bottom: 1px solid $service-border-color;

    @media #{$breakpoint-md} {
      width: 50%;
      cursor: pointer;
      border-right: 1px solid $service-border-color;
    }

    @media #{$breakpoint-lg} {
      width: 33.33%;
    }


    //
    // Padding Top Variants
    //

    @media #{$breakpoint-mobile} {
      &:nth-of-type(n+2) {
        padding-top: 50px;
      }
    }

    @media #{$breakpoint-md-lg} {
      &:nth-of-type(n+3) {
        padding-top: 50px;
      }
    }

    @media #{$breakpoint-lg} {
      &:nth-of-type(n+4) {
        padding-top: 50px;
      }
    }


    //
    // Service Grid Circles to cut off the border like design file
    //

    @media #{$breakpoint-md-lg} {
      &:nth-of-type(1),
      &:nth-of-type(3) {
        &:after {
          @include serviceGridCircleAfter;
        }
      }
    }

    @media #{$breakpoint-lg} {
      &:nth-of-type(-n+2) {
        &:after {
          @include serviceGridCircleAfter;
        }
      }
    }

    //
    // Remove borders where they aren't needed
    //

    @media #{$breakpoint-mobile} {
      &:last-of-type {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }


    @media #{$breakpoint-md-lg} {
      &:nth-of-type(even) {
        border-right: 0;
      }
      &:nth-of-type(n+5) {
        border-bottom: 0;
      }
    }


    @media #{$breakpoint-lg} {
      &:nth-of-type(3),
      &:nth-of-type(6) {
        border-right: 0;
      }

      &:nth-of-type(n+4) {
        border-bottom: 0;
      }
    }


    //
    // Hover effect Borders
    //


    @media #{$breakpoint-md-lg} {
      &:nth-of-type(1),
      &:nth-of-type(3) {
        .service__border--y {
          transform-origin: 100% 0;
          border-right: 2px solid $light-blue;
        }

        .service__border--x {
          width: calc(100% + 15px);
          bottom: -1px;
          transform-origin: 0 0;
          border-bottom: 2px solid $light-blue;
        }
      }

      &:nth-of-type(2),
      &:nth-of-type(4) {
        .service__border--y {
          transform-origin: 100% 0;
          border-left: 2px solid $light-blue;
        }

        .service__border--x {
          width: calc(100% + 15px);
          bottom: -1px;
          left: -15px;
          transform-origin: 100% 0;
          border-bottom: 2px solid $light-blue;
        }
      }

      &:nth-of-type(5) {
        .service__border--y {
          transform-origin: 0 100%;
          top: -15px;
          border-right: 2px solid $light-blue;
        }

        .service__border--x {
          width: calc(100% + 15px);
          top: -1px;
          transform-origin: 0 0;
          border-top: 2px solid $light-blue;
        }
      }

      &:nth-of-type(6) {
        .service__border--y {
          transform-origin: 0 100%;
          top: -15px;
          border-left: 2px solid $light-blue;
        }

        .service__border--x {
          width: calc(100% + 15px);
          top: -1px;
          left: -15px;
          transform-origin: 100% 0;
          border-top: 2px solid $light-blue;
        }
      }

    }


    @media #{$breakpoint-lg} {

      &:nth-of-type(1) {
        .service__border--y {
          transform-origin: 100% 0;
          border-right: 2px solid $light-blue;
        }

        .service__border--x {
          width: calc(100% + 15px);
          bottom: -1px;
          transform-origin: 0 0;
          border-bottom: 2px solid $light-blue;
        }
      }

      &:nth-of-type(2) {
        .service__border--y {
          transform-origin: 100% 0;
          border-left: 2px solid $light-blue;
          border-right: 2px solid $light-blue;
        }

        .service__border--x {
          width: calc(100% + 30px);
          left: -15px;
          bottom: -1px;
          transform-origin: center 0;
          border-bottom: 2px solid $light-blue;
        }
      }

      &:nth-of-type(3) {
        .service__border--y {
          transform-origin: 100% 0;
          border-left: 2px solid $light-blue;
        }

        .service__border--x {
          width: calc(100% + 15px);
          left: -15px;
          bottom: -1px;
          transform-origin: 100% 0;
          border-bottom: 2px solid $light-blue;
        }
      }

      &:nth-of-type(4) {
        .service__border--y {
          transform-origin: 0 100%;
          top: -15px;
          border-right: 2px solid $light-blue;
        }

        .service__border--x {
          border-top: 2px solid $light-blue;
          width: calc(100% + 15px);
          top: -1px;
          transform-origin: 0 0;
        }
      }

      &:nth-of-type(5) {
        .service__border--y {
          transform-origin: 0 100%;
          top: -15px;
          border-left: 2px solid $light-blue;
          border-right: 2px solid $light-blue;
        }

        .service__border--x {
          border-top: 2px solid $light-blue;
          width: calc(100% + 30px);
          left: -15px;
          top: -1px;
          transform-origin: center 0;
        }
      }

      &:nth-of-type(6) {
        .service__border--y {
          transform-origin: 0 100%;
          top: -15px;
          border-left: 2px solid $light-blue;
        }

        .service__border--x {
          border-top: 2px solid $light-blue;
          width: calc(100% + 15px);
          left: -15px;
          top: -1px;
          transform-origin: 100% 0;
        }
      }
    }

    //
    // Service Item Hover
    //

    &:hover {
      .service__overlay {
        opacity: 1;
      }
      .service__border {
        transform: scale(1);
        opacity: 1;
      }
    }

  }

}
