.hero {
  position: relative;

  &__illustration {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;

    svg {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }
}
