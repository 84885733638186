@font-face {
    font-family: 'GT_Walsheim';
    src: url('../fonts/GT_Walsheim/gt-walsheim-web.eot');
    src: url('../fonts/GT_Walsheim/gt-walsheim-web.eot?#iefix') format('embedded-opentype'),
         url('../fonts/GT_Walsheim/gt-walsheim-web.woff') format('woff'),
         url('../fonts/GT_Walsheim/gt-walsheim-web.ttf') format('truetype'),
         url('../fonts/GT_Walsheim/gt-walsheim-web.svg#gt_walsheim_regular') format('svg');
    font-weight: normal;
    font-style: normal;
}
